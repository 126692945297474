.field {
	width: 100%;
	text-align: left;

	&__label {
		@include text(14px, 600, initial, 18px);
	}

	&__wrapper {
		border: 1px solid $graye;
		border-radius: $bdRadius;
		color: $blackTagme;
		margin: 10px 0 20px;
		min-height: 46px;
		width: 100%;
		@include flex(center);
	}

	&__icon {
		display: flex !important;
		height: 24px;
		margin-left: 10px;
		overflow: hidden;
		position: relative;
		transform: translate(0, -2px);
		width: 24px;
		z-index: 1;
		@include flex(center, center);

		&:before {
			font-size: 24px;
			color: $orange;
		}

		&.left {
			order: -1;
			margin-right: -50px;

			& ~ .field__input {
				padding-left: 60px;
			}
		}

		&.right {
			order: 1;
			margin-left: -50px;

			& ~ .field__input {
				padding-right: 50px;
			}
		}
	}

	&__input {
		border: 0;
		border-radius: $bdRadius;
		color: $blackTagme;
		outline: none;
		width: 100%;
		@include text(14px, 400, initial, 18px);

		&::placeholder {
			@include text(14px, 400, initial, 18px);
		}
	}

	&__feedback {
		margin-top: 2px;
		@include title(12px);
		display: block;
		color: $green;
	}

	&__helper {
		margin-top: 5px;
		@include title(11px);
		display: block;
		color: $red;
	}
}
