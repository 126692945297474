@import './theme';
@import './mixin';
@import './app/components/button/button';
@import './app/components/form/form.component';

/* RESET */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 16px;
	vertical-align: baseline;
}

body {
	-webkit-text-size-adjust: none;
	line-height: 1;
	position: relative;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
q:before,
blockquote:after,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

button,
input,
input:focus {
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	outline: none;
}

button {
	transition: 0.5s;

	&:hover,
	&:active,
	&:focus {
		transition: 0.5s;
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* ------------------------ RESET ADMIN ------------------------ */
body {
	color: $blackTagme;
	font-family: 'Open Sans', sans-serif;
}

.wrapper {
	margin: 0 auto;
	padding: 0 16px;
	width: min(940px, 100%);
}

.page {
	min-height: 100%;
	display: flex;
	flex-direction: column;

	&__body {
		background-color: $grayF8;
		flex-grow: 1;
		// padding: 16px 0;
	}
}

/* ------------------------ ANIMATION ------------------------ */
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translate(0px, -100px);
		visibility: hidden;
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
		visibility: visible;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: translate(0px, 0px);
		visibility: visible;
	}
	100% {
		opacity: 0;
		transform: translate(0px, -100px);
		visibility: hidden;
	}
}

body,
html {
	height: 100%;
}

:root {
	--g-pad-inline: 16px;
	--g-max-width: 940px;
}

.spacer {
	height: 12px;
}

.g-max-width {
	max-width: var(--g-max-width);
	padding-inline: var(--g-pad-inline);
	margin: 0 auto;
}
