@import './../../../theme';
@import './../../../mixin';

.button {
	background: $orange;
	border-radius: $bdRadius;
	color: $white;
	cursor: pointer;
	opacity: 1;

	&.disabled {
		opacity: 0.5;
		user-select: none;
	}

	&__light {
		border: 2px solid $orange;
		border-radius: $bdRadius;
		color: $orange;
		cursor: pointer;
	}

	&__filter {
		border: 2px solid $gray69;
		border-radius: $bdRadius;
		color: $blackTagme;
		cursor: pointer;

		&__icon {
			color: $gray69;
		}

		&--selected {
			border: 2px solid $green;
			border-radius: $bdRadius;
			color: $green;

			&__icon {
				color: $green;
			}
		}
	}

	&__del {
		border: 2px solid $red;
		border-radius: $bdRadius;
		color: $red;
		cursor: pointer;

		&--inverse {
			background: $red;
			border-radius: $bdRadius;
			color: $white;
		}
	}

	&__confirm {
		border: 2px solid $green;
		border-radius: $bdRadius;
		color: $green;
		cursor: pointer;

		&--inverse {
			background: $green;
			border-radius: $bdRadius;
			color: $white;
		}
	}

	&__primary {
		border-radius: $bdRadius;
		color: $white;
		cursor: pointer;
	}

	&__gray {
		background: $graye;
		border-radius: 50px;
		color: $gray3C;
		cursor: pointer;
	}

	&__blue {
		background: $blue;
		border-radius: $bdRadius;
		color: $white;
		cursor: pointer;

		&--inverse {
			border: 2px solid $blue;
			border-radius: $bdRadius;
			color: $blue;
			cursor: pointer;
		}
	}
}
