/* Design System :: TAGME COLORS */

$orange: #ff7f40; /* laranja oficial */
$orange6: #ffc4a6;
$orangeC: #fcdecc;
$orangeDgd: linear-gradient(98.62deg, #fd7f3d 0%, #ffc4a6 100%);
$orangeB: #ffebe1;

$blackTagme: #30323b; /* black Tagme */
$grayB7: #b7b7b9;
$gray69: #696970;
$grayA1: #a1a1a6;
$grayA2: #606f86;
$grayA5: #a5a5a9;
$blackDgd: linear-gradient(98.62deg, #30323b 0%, #696970 100%);

$graye: #eaeaea; /* cinza médio */
$grayF8: #f8f8f8;
$white: #fff;
$grayDgd: linear-gradient(98.62deg, #eaeaea 0%, #f8f8f8 100%);

$red: #de1d48; /* vermelho */
$pink5: #fedee5;
$pink6: #fff3f6;
$redDgd: linear-gradient(98.62deg, #de1d48 0%, #fedee5 100%);

$green: #71c879; /* verde */
$greenC: #ccf1cc;
$greenE: #e5ffe7;
$greenDgd: linear-gradient(98.62deg, #71c879 0%, #ccf1cc 100%);

$blue: #04b4f5; /* azul */
$blueC: #c1e4f7;
$blueE: #e1f5ff;
$blueDgd: linear-gradient(98.62deg, #04b4f5 0%, #c1e4f7 100%);

$purple: #40178c;
$purple9: #9880d2; /* lilás */
$purpleD: #d3c2fc;
$purpleE: #efe9ff;
$purpleDgd: linear-gradient(98.62deg, #9880d2 0%, #d3c2fc 100%);

$gold: #d09636; /* dourado */
$gold4: #ffe5a4;
$gold5: #fffde5;
$goldDgd: linear-gradient(98.62deg, #e2ac54 0%, #ffe5a4 100%);

$yellow: #ffc700; /* amarelo */
$yellow4: #f9f084;
$yellow7: #fff8c7;
$yellowDgd: linear-gradient(98.62deg, #ffc700 0%, #f9f084 100%);

$brown: #925115; /* marrom */
$brownD: #d0a76a;
$brownF: #f5ddb8;
$brownDgd: linear-gradient(98.62deg, #925115 0%, #f5ddb8 100%);

$bdRadius: 3px;

/* BRADESCO COLORS */
$gray3C: #3c3c3c;
$dgdBradesco: linear-gradient(180deg, rgb(204, 9, 47) 40%, rgb(184, 21, 112) 90%);

:root {
	--red: #{$red};
	--green: #{$green};
	--pink6: #{$pink6};
	--greenE: #{$greenE};
	--blackTagme: #{$blackTagme};
	--orange: #{$orange};
	--blue: #{$purple};
}
