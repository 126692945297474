.switch {
	// margin-top: 20px;
	@include flex(center, initial, initial);

	&__input {
		height: 0;
		opacity: 0;
		width: 0;
	}

	&__round {
		background-color: $graye;
		border: 1px solid $graye;
		border-radius: 20px;
		cursor: pointer;
		height: 30px;
		transition: 0.4s;
		width: 48px;
		@include flex();

		&::before {
			content: '';
			background-color: $white;
			border: 1px solid $graye;
			border-radius: 50%;
			height: 26px;
			margin-top: 1px;
			transition: transform 0.4s;
			transform: translate(0px);
			width: 26px;
			@include flex();
		}
	}

	&__txt {
		color: $graye;
		margin-left: 10px;
		transition: transform 0.4s;
		width: calc(100% - 48px);
		@include text(14px, 400, initial, 18px);
	}

	.switch__input:checked + .switch__round {
		background-color: $green;

		&::before {
			transform: translate(17px);
		}
	}

	.switch__input:checked ~ .switch__txt {
		color: $green;
		transition: transform 0.4s;
	}

	.switch__input:focus + .switch__round {
		box-shadow: 0 0 1px $graye;
	}
}
